.container-narrow {
	padding-left: 20px;
	padding-right: 20px;
	h1, h2 {
		margin: 30px 0;
		margin-left: -30px;
		padding: 20px 20px 24px;
		padding-left: 50px;
		border-radius: 0 5px 5px 0;
		position: relative;
		box-shadow: 0 1px 5px rgba(0, 0, 0, .3), inset 0 1px 0 rgba(255,255,255,.2), inset 0 -1px 0 rgba(0,0,0,.3);
		background: #7e6d42;
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzdlNmQ0MiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM1YzRlMzUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-linear-gradient(top,  #7e6d42 0%, #5c4e35 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#7e6d42), color-stop(100%,#5c4e35));
		background: -webkit-linear-gradient(top,  #7e6d42 0%,#5c4e35 100%);
		background: -o-linear-gradient(top,  #7e6d42 0%,#5c4e35 100%);
		background: -ms-linear-gradient(top,  #7e6d42 0%,#5c4e35 100%);
		background: linear-gradient(to bottom,  #7e6d42 0%,#5c4e35 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#7e6d42', endColorstr='#5c4e35',GradientType=0 );
	
		&:before {
			content: "";
			position: absolute;
			top: -10px;
			left: 0px;
			border-color: transparent #222 #222 transparent;
			border-style: solid;
			border-width: 5px;
			width: 0;
			height: 0;
		}
	}

	h2 {
		&:before {
			border-color: transparent #6f0d0d #6f0d0d transparent;
		}
		&:after {
		  content: "★";
		  color: #fc0;
		  position: absolute;
		  top: 20px;
		  left: 15px;
		  font-size: 32px;
		  font-weight: bold;
		  text-shadow: 0 -1px 0 rgba(0,0,0,.5);
		}
		font-size:38.5px;
		background: #9e2812;
		background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzllMjgxMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM2ZjBkMGQiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
		background: -moz-linear-gradient(top,#9e2812 0%, #6f0d0d 100%);
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#9e2812), color-stop(100%,#6f0d0d));
		background: -webkit-linear-gradient(top,#9e2812 0%,#6f0d0d 100%);
		background: -o-linear-gradient(top,#9e2812 0%,#6f0d0d 100%);
		background: -ms-linear-gradient(top,#9e2812 0%,#6f0d0d 100%);
		background: linear-gradient(to bottom,#9e2812 0%,#6f0d0d 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9e2812', endColorstr='#6f0d0d',GradientType=0 );
	}
}